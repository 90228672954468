import React, { useState, useEffect } from "react";
import { navigate } from 'gatsby';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";

import { actions } from '../../services/exports';

import Dialog from '../../components/Dialog';
import Link from '../../components/Link';

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootExportProjectDialog: {
      },
    }
  ),
);


interface Props {
  projectId: string;
  open: boolean;
  onClose: () => void;
}

const ExportProjectDialog: React.FC<Props> = ({ open, onClose, projectId, }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const projectData = useSelector((state: RootState) => state.projects.projects[ projectId ]);

  const okCallback = async () => {
    await dispatch(actions.createExport(projectId));
    navigate(`/app/exports?project=${projectId}`);
  }

  const changesAvailable = (projectData.updateTime || 1) >= (projectData.lastExportTime || 1) ? true : false;

  return (
    <Dialog
      title="Export Project"
      open={open}
      cancelCallback={onClose}
      okCallback={okCallback}
      okLabel="Start Export"
      disabled={!changesAvailable}
    >
      {
        changesAvailable ? (
          <React.Fragment>
            <Typography variant="body1" paragraph>Exporting usually finishes in under 1 minute.</Typography>
            <Typography variant="body2" paragraph>You don't need to keep the window open, you can find your export later by visiting "Exports" page.</Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography variant="body1" paragraph>You have not made any changes since the last export.</Typography>
            <Typography variant="body1" paragraph>Go to <Link to={`/app/exports/?project=${projectId}`}>exports</Link> to download it.</Typography>
          </React.Fragment>
        )
      }

    </Dialog>
  );
};

export default ExportProjectDialog;