import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Dialog, DialogTitle, useTheme, useMediaQuery, Slide, IconButton, DialogContent, DialogActions, Button } from "@material-ui/core";
import { TransitionProps } from '@material-ui/core/transitions';

import CloseIcon from '@material-ui/icons/Close';

import FontList from '../../components/Canvas/CanvasObjects/TabFonts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootMyFontsDialog: {
      },
      title: {
        padding: theme.spacing(2),
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[ 500 ],
      },
      content: {
        // backgroundColor: theme.palette.background.default,
      },
      bottom: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
      },
    }
  ),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  onClose: () => void;
}

const MyFontsDialog: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.rootMyFontsDialog}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.title}>
        My Fonts
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <FontList listWidth={window.innerWidth - 36} listHeight={window.innerHeight - 200} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MyFontsDialog;