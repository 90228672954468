import React from "react";
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootAppPage: {
        position: 'relative',
        minHeight: 'calc(100vh - 64px)',
        [ theme.breakpoints.down('sm') ]: {
          minHeight: 'calc(100vh - 56px)',
        },
      },
      addSpacing: {
        padding: theme.spacing(2),
      },
      loadingIndicator: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 4,
        zIndex: theme.zIndex.appBar,
      }
    }
  ),
);

interface Props {
  title: string;
  className?: string;
  disableSpacing?: boolean;
  loading?: boolean;
  disableMobile?: boolean;
}

const AppPage: React.FC<Props> = ({ children, title, className, disableSpacing, loading, disableMobile }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.rootAppPage, { [ classes.addSpacing ]: !disableSpacing }, className)}>
      {
        disableMobile ? (
          <Helmet>
            <meta name="viewport" content="minimum-scale=0" />
          </Helmet>
        ) : null
      }
      {
        loading ? (
          <div className={classes.loadingIndicator}>
            <LinearProgress color="secondary" />
          </div>
        ) : null
      }
      <Helmet>
        <title>{title} - repix.app</title>
      </Helmet>
      {children}
    </div>
  );
};

export default AppPage;