import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import TemplateList from '../../../components/Canvas/TemplatePicker/TemplateList';
import { TemplateData } from "repix-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootStepTemplate: {
        // maxHeight: 550,
        overflow: 'scroll',
      },
    }
  ),
);

interface Props {
  onSelect: (template: TemplateData) => void;
  selectedTemplate?: string;
}

const StepTemplate: React.FC<Props> = ({ onSelect, selectedTemplate }) => {
  const classes = useStyles();

  return (
    <div className={classes.rootStepTemplate}>
      <TemplateList onSelect={onSelect} selectedId={selectedTemplate} />
    </div>
  );
};

export default StepTemplate;