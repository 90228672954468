import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, Typography, ButtonBase, Box } from "@material-ui/core";
import IconAdd from '@material-ui/icons/AddRounded';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootProjectCard: {
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 200,
        height: 100,
      },
      projectCardButton: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
      },
      space: {
        height: theme.spacing(1),
        width: theme.spacing(1),
      },
      addButton: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.secondary.main,
      },
    }
  ),
);

interface Props {
  onClick?: () => void;
  className?: string;
}

const ProjectCard: React.FC<Props> = ({ className, onClick }) => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.rootProjectCard, className)} elevation={1}>
      <ButtonBase className={classes.projectCardButton} onClick={onClick}>
        <div className={classes.addButton}>
          <IconAdd color="inherit" />
          <div className={classes.space} />
          <Typography variant="subtitle2">Create Project</Typography>
        </div>
      </ButtonBase>
    </Paper>
  )
};

export default ProjectCard;