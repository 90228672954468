import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const leftPanelWidth = 320;

const breakpoint = '@media (max-width:1200px)';
const breakpointSmall = '@media (max-width:550px)';

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles(
    {
      rootDesignPage: {
        display: 'flex',
      },
      leftPanel: {
        width: leftPanelWidth,
        boxShadow: 'none',
        backgroundColor: theme.palette.background.paper,
        borderRight: '1px solid ' + theme.palette.divider,
        borderRadius: 0,
        flexShrink: 0,
        flexGrow: 0,
        display: 'flex',
        flexDirection: 'column',

        [ breakpoint ]: {
          display: 'none',
        },
      },
      content: {
        position: 'relative',
        flex: 1,
        flexGrow: 1,
        flexShrink: 1,
        display: 'flex',
        flexDirection: 'column',
      },
      canvasList: {
        position: 'relative',
        flex: 'auto 1 1',
        width: '100%',
        overflowX: 'auto',
        overflowY: 'auto',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        padding: theme.spacing(1, 2, 4, 2),
        maxWidth: `calc(100vw - ${leftPanelWidth}px)`,
        [ breakpoint ]: {
          maxWidth: '100vw',
        },
        [breakpointSmall]: {
          paddingTop: 0,
        }
      },
      paper: {
        overflow: 'hidden',
        borderRadius: 5,
        lineHeight: 0,
        margin: theme.spacing(0, 2),
      },
      paperWrapper: {
        display: 'inline-flex',
        position: 'relative',
        verticalAlign: 'middle',
        flexDirection: 'column',
        alignItems: 'center',

      },
      addButton: {
        [ breakpoint ]: {
          display: 'none',
        },
      },
      addButtonContainer: {
        [ breakpoint ]: {
          display: 'none',
        },
        height: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      fullSpace: {
        flex: 1,
      },
      space: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      exportAndFilter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 2, 0, 2),
        [ breakpointSmall ]: {
          display: 'none',
        }
      },
      exportAndFilterIcons: {
        display: 'none',
        [ breakpointSmall ]: {
          display: 'flex',
        }
      },
      mobileBottomActions: {
        display: 'none',
        [breakpoint]: {
          display: 'flex',
        },
        justifyContent: 'space-between',
        padding: theme.spacing(0, 2, 2, 2),
      },
      mobileBottomIcon: {
        marginRight: theme.spacing(1),
      },
      fabBtn: {
        boxShadow: theme.shadows[1],
      }
    }
  );
});