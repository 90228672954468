import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootStepProjectName: {
        height: 100,
        display: 'flex',
        alignItems: 'center',
      },
    }
  ),
);


interface Props {
  value: string;
  onNameChange: (name: string) => void;
}

const StepProjectName: React.FC<Props> = ({onNameChange, value}) => {
  const classes = useStyles();


  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onNameChange(e.target.value);
  }

  return (
    <div className={classes.rootStepProjectName}>
      <TextField
        label="Project Name"
        value={value}
        onChange={onChange}
        variant="outlined"
        color="secondary"
        fullWidth
      />
    </div>
  );
};

export default StepProjectName;