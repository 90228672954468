import React, { useState, useContext, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  InputBase,
  Button,
  LinearProgress
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBack';
import ExportIcon from '@material-ui/icons/CloudDownloadOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded'
import EditIcon from '@material-ui/icons/EditOutlined';
import IconFonts from '@material-ui/icons/FontDownloadOutlined';

import Logo from "../../components/Logo";
import UserButton from "../../components/UserButton";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { useDialog } from "../../components/DialogProvider";

import { actions } from '../../services/projects';

import ExportDialog from '../components/ExportProjectDialog'
import { Helmet } from "react-helmet";
import MobileDrawer from "./MobileDrawer";
import MyFontsDialog from "./MyFontsDialog";


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      layout: {

      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
      },

      appBarPadding: theme.mixins.toolbar,

      menuButton: {
        marginRight: theme.spacing(1),
        '@media (max-width:1000px)': {
          marginRight: theme.spacing(0),
        }
      },

      content: {
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        height: 'calc(100vh - 64px)',
        [ theme.breakpoints.down('xs') ]: {
          height: 'calc(100vh - 56px)',
        }
      },
      drawerMenu: {
        width: 250,
      },
      drawerHeader: {
        ...theme.mixins.toolbar,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      logo: {
        '@media (max-width:1000px)': {
          display: 'none',
        }
      },
      fullSpace: {
        flex: 1,
      },
      space: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      appBarContent: {
        flex: 1,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
      },
      inputProjectName: {
        borderRadius: theme.spacing(.4),
        border: `1px solid transparent`,
        width: '100%',
        padding: theme.spacing(1.5, 1),
        transition: 'all 200ms',
        marginLeft: 5,
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
        '&:focus': {
          backgroundColor: theme.palette.background.default,
          boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
        },
        '@media (max-width:1000px)': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
        }
      },
      fontsButton: {

      },
      saveStatus: {
        fontStyle: 'italic',
        marginRight: theme.spacing(4),
        '@media (max-width:1000px)': {
          marginRight: theme.spacing(1),
        }
      },
      projectName: {
        width: '100%',
        maxWidth: 300,
        '@media (max-width:1000px)': {
          maxWidth: 180,
        },
        '@media (max-width:550px)': {
          maxWidth: 250,
        }
      },
      editIcon: {
        '@media (max-width:1000px)': {
          display: 'none',
        }
      },
      loadingIndicator: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 4,
        zIndex: theme.zIndex.appBar,
      },
      projectActions: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(4),
        '@media (max-width:1000px)': {
          marginLeft: theme.spacing(0),
        }
      },
      projectActionsRight: {
        display: 'flex',
        '@media (max-width:1100px)': {
          display: 'none',
        }
      },
      projectActionsRightMobile: {
        display: 'none',
        '@media (max-width:1100px)': {
          display: 'flex',
        },
        '@media (max-width:650px)': {
          display: 'none',
        }
      },
      hideOnSmall: {
        '@media (max-width:650px)': {
          display: 'none',
        }
      },
      showOnSmall: {
        display: 'none',
        '@media (max-width:650px)': {
          display: 'inline-block',
        }
      },
      myFontsButton: {
        display: 'none',
        '@media (max-width:1200px)': {
          display: 'inline-flex',
        }
      }
    }
  ),
);

interface Props {
  projectId?: string;
  loading?: boolean;
}


const AppLayout: React.FC<Props> = ({ children, projectId, loading }) => {
  const classes = useStyles();
  const dialog = useDialog();
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => state.projects);
  const [ projectName, setProjectName ] = useState('');

  const [ exportDialog, setExportDialog ] = useState(false);
  const [ myFontsDialog, setMyFontsDialog ] = useState(false);

  const project = projectId ? state.projects[ projectId ] : undefined;

  const [ mobileDrawerOpen, setMobileDrawerOpen ] = useState(false);


  useEffect(() => {
    if (projectName !== '' || !project) {
      return;
    }
    setProjectName(project.name);
  }, [ project ]);

  useEffect(() => {

    const beforeUnload = (event: BeforeUnloadEvent) => {
      if (Object.keys(state.pendingUpdates).length === 0) {
        return;
      }

      event.preventDefault();
      // Google Chrome requires returnValue to be set.
      event.returnValue = '';
    }

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    }

  }, [ Object.keys(state.pendingUpdates).length ])

  const onNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setProjectName(e.target.value);
  }

  const onNameSave = () => {
    if (!project || project.name === projectName) {
      return;
    }

    dialog.confirm({
      title: 'Rename project?',
      onConfirm: () => {
        dispatch(actions.renameProject(project.id, projectName));
      },
      onCancel: () => {
        setProjectName(project.name);
      }
    });
  }

  const openMyFontsDialog = () => {
    setMobileDrawerOpen(false);
    setMyFontsDialog(true);
  }
  const closeMyFontsDialog = () => {
    setMyFontsDialog(false);
  }

  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  }

  const onBack = () => {
    navigate('/app/', { replace: true });
  }

  const onDelete = () => {
    if (!project) {
      return;
    }

    dialog.confirm({
      title: `Are you sure, you want to delete?`,
      subtitle: 'This action can not be undone. You will lose access to this project.',
      onConfirm: () => {
        dispatch(actions.deleteProject(project.id));
        navigate('/app/');
      }
    })
  }

  const openExportDialog = () => {
    setExportDialog(true);
  }
  const closeExportDialog = () => {
    setExportDialog(false);
  }

  const hasPendingUpdates = Object.keys(state.pendingUpdates).length > 0;

  return (
    <div className={classes.layout}>
      <Helmet>
        <title>{project ? project.name : ''} - repix.app</title>
      </Helmet>
      <AppBar position="fixed" elevation={1} className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={onBack}>
            <BackIcon />
          </IconButton>
          <Logo linkTo="/" className={classes.logo} />
          <div className={classes.projectActions}>
            {
              project ? (
                <React.Fragment>
                  <InputBase
                    value={projectName}
                    onBlur={onNameSave}
                    onChange={onNameChange}
                    classes={{ input: classes.inputProjectName, root: classes.projectName }}
                    startAdornment={<EditIcon color="action" fontSize="small" className={classes.editIcon} />}
                  />
                  <div className={classes.fullSpace} />
                  <div className={classes.hideOnSmall}>
                    <Typography variant="caption" className={classes.saveStatus}>
                      {
                        hasPendingUpdates ? 'Unsaved Changes...' : 'All Changes Saved'
                      }
                    </Typography>
                  </div>
                  <div className={classes.projectActionsRight}>
                    {
                      project && project.exportInProgress ? (
                        <Button startIcon={<ExportIcon />} component={Link} to={`/app/exports?project=${project.id}`}>Download</Button>
                      ) : (
                          <Button startIcon={<ExportIcon />} onClick={openExportDialog}>Download</Button>
                        )
                    }
                    <Button startIcon={<DeleteIcon />} onClick={onDelete}>Delete</Button>
                    <Button startIcon={<IconFonts />} onClick={openMyFontsDialog} className={classes.myFontsButton}>My Fonts</Button>
                  </div>
                  <div className={classes.projectActionsRightMobile}>
                    {
                      project && project.exportInProgress ? (
                        <IconButton color="inherit" component={Link} to={`/app/exports?project=${project.id}`}><ExportIcon /></IconButton>
                      ) : (
                          <IconButton color="inherit" onClick={openExportDialog}><ExportIcon /></IconButton>
                        )
                    }
                    <IconButton color="inherit" onClick={onDelete}><DeleteIcon /></IconButton>
                    <IconButton color="inherit" onClick={openMyFontsDialog}><IconFonts /></IconButton>
                  </div>
                </React.Fragment>
              ) : null
            }
          </div>

          <div className={classes.hideOnSmall}>
            <UserButton />
          </div>
          <div className={classes.showOnSmall}>
            <IconButton color="inherit" onClick={toggleMobileDrawer}>
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <MyFontsDialog open={myFontsDialog} onClose={closeMyFontsDialog} />

      <MobileDrawer
        open={mobileDrawerOpen}
        onClose={toggleMobileDrawer}
        onDelete={onDelete}
        onExport={openExportDialog}
        openFontsDialog={openMyFontsDialog}
      />

      {
        project ? <ExportDialog open={exportDialog} onClose={closeExportDialog} projectId={project.id} /> : null
      }

      <div className={classes.appBarPadding}></div>
      <main className={classes.content}>
        {
          loading ? (
            <div className={classes.loadingIndicator}>
              <LinearProgress color="secondary" />
            </div>
          ) : null
        }
        {children}
      </main>



    </div>
  );
};

export default AppLayout;