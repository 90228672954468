import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Collapse, ListSubheader } from "@material-ui/core";
import ExportIcon from '@material-ui/icons/CloudDownloadOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded';
import AccountIcon from '@material-ui/icons/AccountCircle';
import IconFonts from '@material-ui/icons/FontDownloadOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { actions as userActions } from '../../services/user';


import Logo from "../../components/Logo";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import { ImportFrom } from '../../components/Canvas/ImportFrom';
import FilterToggles from '../../components/Canvas/FilterToggles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootMobileDrawer: {
      },
      drawerMenu: {
        width: 250,
      },
      drawerHeader: {
        ...theme.mixins.toolbar,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      logo: {
        width: 120,
      },
      nested: {
        paddingLeft: theme.spacing(4),
      },
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  onExport: () => void;
  openFontsDialog: () => void;
}


const MobileDrawer: React.FC<Props> = ({ open, onClose, onDelete, onExport, openFontsDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user.user);

  const [ accountExpanded, setAccountExpanded ] = useState(false);

  const toggleAccountExpanded = () => {
    setAccountExpanded(!accountExpanded);
  }

  const _onDelete = () => {
    onClose();
    onDelete();
  }
  const _onDownload = () => {
    onClose();
    onExport();
  }

  const signOut = () => {
    dispatch(userActions.signOut());
  }



  return (
    <Drawer
      classes={{
        paper: classes.drawerMenu,
      }}
      anchor="right"
      open={open}
      onClose={onClose}
      keepMounted
    >
      <div className={classes.drawerHeader}>
        <Logo linkTo="/app/" />
      </div>
      <Divider />
      <List>
        <ListItem button onClick={toggleAccountExpanded}>
          <ListItemIcon>
            <AccountIcon />
          </ListItemIcon>
          <ListItemText primary={user?.firstName} />
          {accountExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={accountExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {
              user?.roles.includes('admin') ? (
                <ListItem className={classes.nested} button component={Link} to="/admin">
                  <ListItemText primary="Admin" />
                </ListItem>
              ) : null
            }
            <ListItem className={classes.nested} button component={Link} to="/user/account">
              <ListItemText primary="My Account" />
            </ListItem>
            <ListItem className={classes.nested} button onClick={signOut}>
              <ListItemText primary="Sign Out" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={openFontsDialog}>
          <ListItemIcon>
            <IconFonts />
          </ListItemIcon>
          <ListItemText primary="My Fonts" />
        </ListItem>

        <ListItem button onClick={_onDownload}>
          <ListItemIcon>
            <ExportIcon />
          </ListItemIcon>
          <ListItemText primary="Download" />
        </ListItem>
        <ListItem button onClick={_onDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </ListItem>
        <Divider />
        <ImportFrom listMode onCloseDrawer={onClose} />
        <FilterToggles listMode onCloseDrawer={onClose} />
        <Divider />

      </List>
    </Drawer>
  );
};

export default MobileDrawer;