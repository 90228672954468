import React, { useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Typography,
  Paper,
  Toolbar,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import AppPage from "./components/AppPage";
import { useQueryParams, StringParam } from 'use-query-params';


import orderBy from 'lodash/orderBy';

import { actions } from '../services/exports';
import { actions as projectActions } from '../services/projects';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";

import ExportCard from './components/ExportCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootExportPage: {
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },

      list: {
        marginTop: theme.spacing(4),
      },

      noExports: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }
    }
  ),
);

const ExportPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const exports = useSelector((state: RootState) => state.exports);
  const projects = useSelector((state: RootState) => state.projects.projects);

  const [ params, setParams ] = useQueryParams({
    project: StringParam
  })

  useEffect(() => {
    dispatch(projectActions.loadProjectsLive());
    dispatch(actions.loadListLive());
  }, []);

  const onProjectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setParams({ project: event.target.value as string });
  };


  const projectList = orderBy(projects, [ 'lastOpenTime', 'updateTime', 'createTime' ], [ 'desc', 'desc', 'desc' ]);

  const exportList = params.project && params.project !== 'all' ? exports.exports.filter(item => item.project === params.project) : exports.exports;

  const selectedProject = params.project || 'all';

  return (
    <AppPage className={classes.rootExportPage} title="Exports" loading={exports.listLoading}>
      <Container maxWidth="md">
        <Paper variant="outlined">
          <Toolbar disableGutters>
            <FormControl className={classes.formControl} variant="outlined" size="small">
              <InputLabel id="project-list">Project</InputLabel>
              <Select
                labelId="project-list"
                labelWidth={55}
                value={selectedProject}
                onChange={onProjectChange}
              >
                <MenuItem value="all">All Projects</MenuItem>
                {
                  projectList.map(project => (
                    <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Toolbar>
        </Paper>

        {
          exportList.length === 0 && !exports.listLoading ? (
            <div className={classes.noExports}>
              <Typography variant="h6">
                You don't have any exports yet.
              </Typography>
            </div>
          ) : null
        }

        <div className={classes.list}>
          {
            exportList.map(item => {
              const project = projects[ item.project ];
              return (
                <ExportCard key={item.id} projectData={project} exportData={item} />
              )
            })
          }
        </div>
      </Container>

    </AppPage>
  );
};

export default ExportPage;