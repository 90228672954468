import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Fab,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  IconButton,
  Typography
} from "@material-ui/core";

import SwipeableViews from 'react-swipeable-views';
import IconBack from '@material-ui/icons/ArrowBack'


import IconText from '@material-ui/icons/FormatSizeRounded';
import IconImage from '@material-ui/icons/InsertPhoto';
import IconDevices from '@material-ui/icons/Devices';
import IconElementes from '@material-ui/icons/DashboardOutlined';
import IconShapes from './icons/shapes.svg';

import IconAddObject from '@material-ui/icons/Add';
import { useCanvas } from "../CanvasProvider";

import TabDevices from './TabDevices';
import TabText from "./TabText";
import TabImages from "./TabImages";
import TabElements from "./TabElementsMobile";
import TabShapes from "./TabShapes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      drawerPaper: {
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1),
      },
      content: {
        height: 256,
        overflow: 'hidden',
        transition: 'all 200ms',
      },
      listItem: {
        height: 48,
      },
      contentLong: {
        height: 'calc(100vh - 150px)',
      },
      mobileBottomIcon: {
        marginRight: theme.spacing(1),
      },
      svgIcon: {
        width: 28,
      },
    }
  ),
);

interface Props {
  className?: string;
}

const AddObjectButton: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  const { activeCanvas } = useCanvas();

  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ section, setSection ] = useState<string>();
  const [ transitionDone, setTransitionDone ] = useState(false);

  const heights: { [ section: string ]: number } = {
    'devices': 400,
    'text': 150,
    'images': 520,
    'elements': 440,
    'shapes': 300,
  }

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  }

  const updateSection = (section: string) => {
    setSection(section);
    setTimeout(() => {
      setTransitionDone(true);
    }, 200);
  }

  const resetSection = () => {
    setSection(undefined);
    setTransitionDone(false);
  }

  useEffect(() => {
    if (!drawerOpen) {
      resetSection();
    }
  }, [ drawerOpen ])

  const renderSection = () => {
    if (!transitionDone || !activeCanvas) {
      return null;
    }

    if (section === 'devices') {
      return <TabDevices horizontal onAdd={toggleDrawer} activeCanvas={activeCanvas} />;
    }
    else if (section === 'text') {
      return <TabText onAdd={toggleDrawer} />;
    }
    else if (section === 'images') {
      return <TabImages horizontal onAdd={toggleDrawer} activeCanvas={activeCanvas} categoriesAsChips/>;
    }
    else if (section === 'elements') {
      return <TabElements onAdd={toggleDrawer} activeCanvas={activeCanvas} />
    }
    else if (section === 'shapes') {
      return <TabShapes horizontal onAdd={toggleDrawer} />
    }

    return section;
  }

  const height = section && heights[ section ] ? heights[ section ] : 256;


  return (
    <React.Fragment>
      <Fab color="primary" size="medium" variant="extended" className={className} disabled={!Boolean(activeCanvas)} onClick={toggleDrawer}>
        <IconAddObject color="action" className={classes.mobileBottomIcon} />
        Add Object
      </Fab>
      <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer} classes={{ paper: classes.drawerPaper }}>
        <div className={clsx(classes.content)} style={{ height }}>
          {
            !Boolean(section) ? (
              <List>
                <ListItem button onClick={() => updateSection('devices')} className={classes.listItem}>
                  <ListItemIcon>
                    <IconDevices />
                  </ListItemIcon>
                  <ListItemText primary="Devices" />
                </ListItem>

                <ListItem button onClick={() => updateSection('text')} className={classes.listItem}>
                  <ListItemIcon>
                    <IconText />
                  </ListItemIcon>
                  <ListItemText primary="Text" />
                </ListItem>

                <ListItem button onClick={() => updateSection('images')} className={classes.listItem}>
                  <ListItemIcon>
                    <IconImage />
                  </ListItemIcon>
                  <ListItemText primary="Images" />
                </ListItem>

                <ListItem button onClick={() => updateSection('elements')} className={classes.listItem}>
                  <ListItemIcon>
                    <IconElementes />
                  </ListItemIcon>
                  <ListItemText primary="Elements" />
                </ListItem>

                <ListItem button onClick={() => updateSection('shapes')} className={classes.listItem}>
                  <ListItemIcon>
                    <IconShapes className={classes.svgIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Shapes" />
                </ListItem>
              </List>
            ) : (
                <div>
                  <Toolbar disableGutters>
                    <IconButton onClick={resetSection}>
                      <IconBack />
                    </IconButton>
                    <Typography>{section ? section.charAt(0).toUpperCase() + section.slice(1) : ''}</Typography>
                  </Toolbar>
                  {renderSection()}
                </div>
              )
          }

        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default AddObjectButton;