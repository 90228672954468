import React from "react"
import { Router } from "@reach/router"

import AppLayout from "../app/components/AppLayout";

import PrivateRoute from '../user/components/PrivateRoute';

import MyProjects from '../app/MyProjects';
import Design from '../app/Design/Design';
import Exports from '../app/Exports';


const AppRoutes = () => (
  <AppLayout>
    <Router>
      <MyProjects path="/app/" />
      <Exports path="/app/exports" />
    </Router>
  </AppLayout>
)

const App = () => {
  return (
    <Router>
      <PrivateRoute component={AppRoutes} default />
      <PrivateRoute component={Design} path="/app/projects/:projectId"/>
    </Router>
  )
}

export default App
