import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import clsx from 'clsx';
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button, Fab } from "@material-ui/core";

import { useQueryParams, BooleanParam } from 'use-query-params';

import IconAdd from '@material-ui/icons/AddRounded';


import orderBy from 'lodash/orderBy';

import AppPage from "./components/AppPage";

import { actions } from '../services/projects';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";

import CreateProjectDialog from './components/CreateProjectDialog/CreateProjectDialog';
import ProjectCard from './components/ProjectCard';
import AddProjectButton from './components/CreateProjectButton';
import { AppBarContent } from "./components/AppLayout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootMyProjects: {

      },
      list: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        // justifyContent: 'center',
        marginTop: theme.spacing(2),
      },
      masonry: {
        marginTop: theme.spacing(2),
        // marginLeft: theme.spacing(2) * -1,
        display: 'flex',
        width: 'auto',
        '@media (max-width:440px)': {

        }
      },
      masonryCol: {
        // paddingLeft: theme.spacing(2),
        // backgroundClip: 'padding-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      addButtonCentered: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      addButton: {

      },
      projectCard: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      appBarContent: {
        flex: 1,
        justifyContent: 'flex-end',
        display: 'flex',
        '@media (max-width:480px)': {
          display: 'none',
        }
      },
      fabAddButton: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        display: 'none',
        '@media (max-width:480px)': {
          display: 'flex',
        },
        zIndex: theme.zIndex.appBar,
      }
    }
  ),
);

const MyProjects: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const projects = useSelector((state: RootState) => state.projects);
  const [ size, setSize ] = useState(window.innerWidth);

  const [ params, setParams ] = useQueryParams({
    dialog: BooleanParam
  })

  const openDialog = () => {
    setParams({
      ...params,
      dialog: true
    })
  }

  const closeDialog = () => {
    setParams({
      ...params,
      dialog: false
    }, 'replaceIn')
  }

  const onProjectCreate = (id: string) => {
    console.log('Project created', id);
    navigate('/app/projects/' + id);
  }

  useEffect(() => {
    dispatch(actions.loadProjectsLive());
  }, []);

  useEffect(() => {
    const onResize = () => {
      setSize(window.innerWidth);
    }

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  const list = orderBy(projects.projects, [ 'lastOpenTime', 'updateTime', 'createTime' ], [ 'desc', 'desc', 'desc' ]);


  return (
    <AppPage className={classes.rootMyProjects} title="My Projects" loading={projects.listLoading}>
      <AppBarContent>
        <div className={classes.appBarContent}>
          <Button variant="contained" onClick={openDialog} disableElevation startIcon={<IconAdd />} color="secondary">Create Project</Button>
        </div>
      </AppBarContent>
      <Fab className={classes.fabAddButton} color="secondary" variant="extended" onClick={openDialog}>
        <IconAdd />
        Create Project
      </Fab>
      {
        list.length === 0 && !projects.listLoading ? <AddProjectButton className={clsx(classes.addButton, { [ classes.addButtonCentered ]: list.length === 0 })} onClick={openDialog} /> : null
      }
      {
        projects.listLoading ? null : (

          <React.Fragment>
            <div className={classes.list}>
              {
                list.map(project => <ProjectCard key={project.id} project={project} className={classes.projectCard} />)
              }
            </div>
          </React.Fragment>
        )
      }
      <CreateProjectDialog open={Boolean(params.dialog)} onCancel={closeDialog} onCreate={onProjectCreate} />

    </AppPage>
  );
};

export default MyProjects;