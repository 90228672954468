const timeAgo = (milliseconds: number): string => {
  let time = new Date(milliseconds);
  let monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  let diff = (new Date().getTime() - time.getTime()) / 1000;
  let months = Math.floor(diff / (30 * 86400));
  diff -= months * (30 * 86400);
  let weeks = Math.floor(diff / (7 * 86400));
  diff -= weeks * (7 * 86400);
  let days = Math.floor(diff / 86400);
  diff -= days * 86400;
  let hours = Math.floor(diff / 3600);
  diff -= hours * 3600;
  let minutes = Math.floor(diff / 60);
  diff -= minutes * 60;
  let seconds = diff;

  if (months >= 2) {
    let monthName = monthNames[ time.getMonth() ];
    return `${monthName} ${time.getDate()}, ${time.getFullYear()}`;
  } else if (months === 1) {
    return 'Month ago';
  } else if (weeks > 0) {
    return weeks === 1 ? 'Week ago' : `${weeks} weeks ago`;
  } else if (days > 0) {
    return days === 1 ? 'Yesterday' : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? 'Hour ago' : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? 'Minute ago' : `${minutes} minutes ago`;
  } else if (seconds > 0) {
    return minutes < 10 ? 'Few seconds ago' : `${seconds} ago ago`;
  }

  return time.toString();
};

export default timeAgo;