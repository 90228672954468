import React from "react";
import clsx from 'clsx';

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, Typography, LinearProgress, Box, IconButton } from "@material-ui/core";
import IconDownload from '@material-ui/icons/CloudDownloadOutlined';
import { ExportData, ProjectData } from "repix-common";
import timeAgo from "../../utils/timeAgo";

import Link from '../../components/Link';

import Button from '../../components/ProgressButton';
import { trackEvent } from "../../utils/analytics";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootExportCard: {
        padding: theme.spacing(2),
        position: 'relative',
        margin: theme.spacing(2, 0),
        overflow: 'hidden',
      },
      inProgress: {
        paddingBottom: theme.spacing(3),
      },
      progress: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 8,
      },
      progressDone: {
        height: 2,
      },
      space: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      errorMessage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      projectLink: {
        color: theme.palette.text.primary,
        cursor: 'pointer',
        fontSize: 19,

      },
      downloadBtn: {
        '@media (max-width:550px)': {
          display: 'none',
        },
      },
      downloadBtnMobile: {
        display: 'none',
        '@media (max-width:550px)': {
          display: 'inline-flex',
        },
      },
    }
  ),
);

interface Props {
  exportData: ExportData;
  projectData: ProjectData;
  className?: string;
}
const ExportCard: React.FC<Props> = ({ exportData, projectData, className }) => {

  const classes = useStyles();

  return (
    <Paper className={clsx(classes.rootExportCard, className, { [ classes.inProgress ]: exportData.status === 'pending' })}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div>
          <Box display="flex" alignItems="center">
            <Typography
              component="h5"
              noWrap
              component={Link}
              className={classes.projectLink}
              to={`/app/projects/${projectData.id}`}
            >
              {projectData?.name}
            </Typography>
            <div className={classes.space} />
            <div className={classes.space} />

            {
              exportData.canvasCount ? <Typography variant="caption">{exportData.canvasCount} design(s)</Typography> : null
            }
          </Box>
          <Typography variant="caption">{timeAgo(exportData.createTime as number)}</Typography>
        </div>
        <div>
          <Button
            className={classes.downloadBtn}
            variant="outlined"
            startIcon={<IconDownload />}
            loading={Boolean(exportData.status === 'pending')}
            disabled={exportData.status !== 'active'}
            component="a"
            href={`${process.env.CDN_URL}/${exportData.downloadPath}`}
            onClick={() => {
              trackEvent('export_download');
            }}
          >
            Download
          </Button>

          <IconButton
            className={classes.downloadBtnMobile}
            disabled={exportData.status !== 'active'}
            component="a"
            href={`${process.env.CDN_URL}/${exportData.downloadPath}`}
            onClick={() => {
              trackEvent('export_download');
            }}
          >
            <IconDownload />
          </IconButton>
        </div>
      </Box>
      <LinearProgress
        variant={exportData.progress === 0 ? 'indeterminate' : 'determinate'}
        value={exportData.progress}
        className={clsx(classes.progress, { [ classes.progressDone ]: exportData.status === 'active' })}
      />
      {
        exportData.status === 'error' ? (
          <div className={classes.errorMessage}>
            <Typography color="error">{exportData.errorReason}</Typography>
          </div>
        ) : null
      }
    </Paper>
  );
};

export default ExportCard;