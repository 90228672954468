import React, { useState, useContext } from "react";
import { Link } from "gatsby";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  IconButton,
  Divider,
  Portal,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import IconProjects from '@material-ui/icons/List'
import IconExports from '@material-ui/icons/CloudDownloadOutlined'

import Logo from "../../components/Logo";
import UserButton from "../../components/UserButton";


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      layout: {
        display: 'flex',
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
      },

      appBarPadding: theme.mixins.toolbar,
      menuButton: {
        marginRight: theme.spacing(1),
      },
      content: {
        flexGrow: 1,
        height: '100vh',
      },
      drawerMenu: {
        width: 250,
      },
      drawerHeader: {
        ...theme.mixins.toolbar,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      logo: {
        width: 120,
      },
      fullSpace: {
        flex: 1,
      },
      appBarContent: {
        flex: 1,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
      }
    }
  ),
);

interface IRefs {
  appBarRef?: HTMLDivElement,
}

export const Context = React.createContext<IRefs>({});

const AppLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const [ drawerOpen, setDrawerOpen ] = useState(false);

  const [ appBarRef, setAppBarRef ] = useState();

  const handleAppBarRef = React.useCallback(node => {
    if (node !== null) {
      setAppBarRef(node);
    }
  }, []);

  const openDrawerMenu = () => {
    setDrawerOpen(true);
  }

  const closeDrawerMenu = () => {
    setDrawerOpen(false);
  }

  return (
    <Context.Provider value={{ appBarRef: appBarRef }}>
      <div className={classes.layout}>
        <AppBar position="fixed" elevation={1} className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={openDrawerMenu}>
              <MenuIcon />
            </IconButton>
            <Logo linkTo="/" />
            <div ref={handleAppBarRef} className={classes.appBarContent} />

            {/* <div className={classes.fullSpace} /> */}
            <UserButton />
          </Toolbar>
        </AppBar>

        <Drawer
          classes={{
            paper: classes.drawerMenu,
          }}
          anchor="left"
          open={drawerOpen}
          onClose={closeDrawerMenu}
        >
          <div className={classes.drawerHeader}>
            <Logo linkTo="/app/" />
          </div>
          <Divider />
          <List>
            <ListItem button component={Link} to="/app/" onClick={closeDrawerMenu}>
              <ListItemIcon>
                <IconProjects />
              </ListItemIcon>
              <ListItemText primary="My Projects" />
            </ListItem>
            <ListItem button component={Link} to="/app/exports" onClick={closeDrawerMenu}>
              <ListItemIcon>
                <IconExports />
              </ListItemIcon>
              <ListItemText primary="Exports" />
            </ListItem>
          </List>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.appBarPadding}></div>
          {children}
        </main>

      </div>
    </Context.Provider>
  );
};

export default AppLayout;


export const AppBarContent: React.FC = ({ children }) => {
  const context = useContext(Context);
  return (
    <Portal container={context.appBarRef}>
      {children}
    </Portal>
  )
}