import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, ButtonBase, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootStepDesignType: {
        display: 'flex',
        padding: theme.spacing(2),
        justifyContent: 'center',
        flexWrap: 'wrap',

      },
      paper: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '&last-child': {
          marginRight: theme.spacing(0),
        }
      },
      selected: {
        backgroundColor: theme.palette.divider,
      },
      space: {
        width: theme.spacing(1),
      },
      btn: {
        padding: theme.spacing(2),
        width: 200,
        height: 100,
      }
    }
  ),
);

export type DesignType = 'blank' | 'tempalte';

interface Props {
  value?: DesignType;
  onChange: (type: DesignType) => void;
}

const StepDesignType: React.FC<Props> = ({ onChange, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.rootStepDesignType}>

      <Paper variant="outlined" className={clsx(classes.paper, {[classes.selected]: value === 'blank'})}>
        <ButtonBase className={classes.btn} onClick={() => onChange('blank')}>
          <Typography variant="button">Blank Design</Typography>
        </ButtonBase>
      </Paper>

      <Paper variant="outlined" className={clsx(classes.paper, {[classes.selected]: value === 'tempalte'})}>
        <ButtonBase className={classes.btn} onClick={() => onChange('tempalte')}>
          <Typography variant="button">
            Use Template
          </Typography>
        </ButtonBase>
      </Paper>
    </div>
  );
};

export default StepDesignType;