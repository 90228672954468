import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, Typography, ButtonBase, Box } from "@material-ui/core";
import IconAdd from '@material-ui/icons/AddRounded';
import CanvasIcon from '@material-ui/icons/InsertDriveFileOutlined';
import orderBy from 'lodash/orderBy';

import { ProjectData } from "repix-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootProjectCard: {
        overflow: 'hidden',
        '&:hover': {
          boxShadow: theme.shadows[ 5 ],
        },
      },
      projectCardButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      title: {
        padding: theme.spacing(1),
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      },
      fullSpace: {
        flex: 1,
      },
      space: {
        height: theme.spacing(1),
        width: theme.spacing(1),
      },
      addButton: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.secondary.main,
      },
      canvasIcon: {
        fontSize: 15,
      },
      canvasCount: {
        display: 'flex',
        alignItems: 'center',
      },
    }
  ),
);

interface Props {
  project: ProjectData;
  onClick?: () => void;
  className?: string;
  height?: number;
}

const ProjectCard: React.FC<Props> = ({ className, project, onClick, height: argHeight = 230 }) => {
  const classes = useStyles();
  const padding = 48;
  const [ maxWidth, setMaxWidth ] = useState(window.innerWidth - padding);

  useEffect(() => {
    const onResize = () => {
      setMaxWidth(window.innerWidth - padding);
    }

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  const thumbs = orderBy(project.thumbs || [], 'updateTime', 'desc');
  const thumb = thumbs[ 0 ];
  const thumbWidth = thumb ? thumb.width : 200;
  const thumbHeight = thumb ? thumb.height : argHeight;

  let scaleRatio = thumbHeight / argHeight;
  let height = argHeight;
  let width = thumbWidth / scaleRatio;

  if (width > maxWidth) {
    width = maxWidth;
    scaleRatio = thumbWidth / maxWidth;
    height = thumbHeight / scaleRatio;
  }

  const cardHeight = height + 37;

  return (
    <Paper className={clsx(classes.rootProjectCard, className)} elevation={1} variant="elevation">
      <ButtonBase className={classes.projectCardButton} component={Link} to={`/app/projects/${project.id}`} style={{ width, height: cardHeight }}>
        {
          thumb ? (
            <img src={`${process.env.CDN_URL}/${thumb.path}`} style={{ height, width }} />
          ) : <div className={classes.fullSpace} />
        }


        <div className={classes.title}>
          <Typography variant="subtitle2" noWrap>{project.name}</Typography>
          <div className={classes.canvasCount}>
            <CanvasIcon className={classes.canvasIcon} color="action" />
            <div className={classes.space} />
            <Typography variant="caption">{project.canvasCount}</Typography>
          </div>
        </div>
      </ButtonBase>
    </Paper>
  );
};

export default ProjectCard;