import React, { useEffect, useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, TextField, Chip } from "@material-ui/core";
import {
  FixedSizeList as List,
  ListChildComponentProps,
  GridChildComponentProps,
  FixedSizeGrid as Grid,
  areEqual,
} from 'react-window';


import { actions } from '../../../services/designElements';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { loadSvgStr } from "../../../utils/fabricHelper";
import { Autocomplete } from "@material-ui/lab";
import { DesignElementCategory, DesignElement } from "repix-common";
import { fabric } from "fabric";
import memoizeOne from "memoize-one";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootTabElements: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      space: {
        height: theme.spacing(1),
      },
      item: {
        paddingBottom: theme.spacing(2),
        textAlign: 'center',
      },
      img: {
        width: 80,
        height: 80,
        cursor: 'pointer',
      },
      catList: {
        display: 'flex',
        width: '100%',
        overflow: 'auto',
        padding: theme.spacing(1, 1, 2, 1),
      },
      catItem: {
        flex: '0 0 auto',
        marginRight: theme.spacing(1),
      }
    }
  ),
);

const ElemGridItem: React.FC<GridChildComponentProps> = React.memo(({ columnIndex, rowIndex, style, data }) => {
  const classes = useStyles();
  const list = data.list as DesignElement[];
  const onClick = data.onClick;
  const colCount = Math.ceil(list.length / 3);
  const index = rowIndex * colCount + columnIndex;

  if (index >= list.length) {
    return null;
  }

  const element = list[ index ];

  return (
    <div style={style} className={classes.item}>
      <img key={element.id} src={`${process.env.CDN_URL}/${element.asset.path}`} className={classes.img} onClick={() => { onClick(element) }} />
    </div>
  );
}, areEqual);

const createElemItemData = memoizeOne((list, onClick) => ({
  list,
  onClick,
}))


interface Props {
  onAdd?: () => void;
  activeCanvas: fabric.Canvas;
}

const TabElements: React.FC<Props> = ({ onAdd, activeCanvas }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ category, setCategory ] = useState<DesignElementCategory>();

  const svgElements = useSelector((state: RootState) => state.designElements);

  useEffect(() => {
    if (typeof category === 'undefined' && svgElements.categories.length > 0) {
      const defaultIndex = svgElements.categories.findIndex(cat => cat.default);

      setCategory(defaultIndex === -1 ? svgElements.categories[ 0 ] : svgElements.categories[ defaultIndex ]);
    }
  }, [ svgElements.categories.length ])

  useEffect(() => {
    if (!category) {
      dispatch(actions.loadCategories());
      return;
    }

    dispatch(actions.loadList(category.id));
  }, [ category ]);

  if (!category) {
    return null;
  }

  const onClick = async (element: DesignElement) => {
    onAdd && onAdd();
    // const img = await loadSvg(`${process.env.CDN_URL}/${element.asset.path}`);
    const img = await loadSvgStr(`${process.env.CDN_URL}/${element.asset.path}`);


    img.scaleToWidth(200);
    img.name = 'design_element';
    img.data = {
      elementId: element.id,
      type: element.type,
    }

    activeCanvas?.add(img);
    activeCanvas?.updated();
  }

  const list = svgElements.list[ category.id ];
  const itemData = createElemItemData(list, onClick);

  return (
    <div className={classes.rootTabElements}>
      {/* <Autocomplete
        options={svgElements.categories}
        getOptionLabel={(option) => option.name}
        style={{ width: window.innerWidth - 36 }}
        renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
        onChange={(e, newValue) => { setCategory(newValue) }}
        value={category}
        disableClearable
      /> */}
      <div className={classes.catList}>
        {
          svgElements.categories.map(cat => (
            <Chip
              key={cat.id}
              label={cat.name}
              className={classes.catItem}
              onClick={() => setCategory(cat)}
              color={category.id === cat.id ? 'secondary' : 'default'}
            />
          ))
        }
      </div>
      <div className={classes.space} />
      <Grid
        columnCount={list ? Math.ceil(list.length / 3) : 0}
        rowCount={3}
        columnWidth={window.innerWidth / 3.5}
        rowHeight={100}
        width={window.innerWidth}
        height={300}
        itemData={itemData}
      >
        {ElemGridItem}
      </Grid>
    </div>
  );
};

export default React.memo(TabElements);