import React, { useState, useEffect } from "react";

import SwipeableViews from 'react-swipeable-views';


import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, TextField } from "@material-ui/core";
import Dialog from '../../../components/Dialog';

import { actions } from '../../../services/projects';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";

import StepProjectName from './StepProjectName';
import StepDesignType, { DesignType } from './StepDesignType';
import StepTemplate from './StepTemplate';
import { TemplateData } from "repix-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootCreateProjectLayout: {
      },
      spacer: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      }
    }
  ),
);

interface DialogState {
  projectName: string;
  designType?: DesignType;
  tempalteId?: string;
}

interface Props {
  open: boolean;
  onCancel: () => void;
  onCreate: (projectId: string) => void;
}

const CreateProjectDialog: React.FC<Props> = ({ open, onCancel, onCreate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ state, setState ] = useState<DialogState>({
    projectName: ''
  })

  const [ step, setStep ] = useState(0);

  const createProjectPending = useSelector((state: RootState) => state.projects.createProjectPending);

  useEffect(() => {

    if (!open) {
      setState({ projectName: '' });
      setStep(0);
    }

  }, [ open ]);

  const onNameChange = (name: string) => {
    setState({
      ...state,
      projectName: name
    })
  }

  const onDesignType = (designType: DesignType) => {
    setState({
      ...state,
      designType,
    })
    if (designType === 'tempalte') {
      setStep(2);
    }

  }

  const onSelectTemplate = (template: TemplateData) => {
    setState({
      ...state,
      tempalteId: template.id,
    })
  }

  const getLabel = () => {
    if (step === 0) {
      return 'Continue';
    }
    else if (step === 1) {
      return state.designType === 'blank' ? 'create' : 'continue';
    }
    else if (step === 2) {
      return 'Create';
    }

    return 'Ok';
  }

  const canContinue = () => {
    if (step === 0) {
      return Boolean(state.projectName);
    }
    else if (step === 1) {
      return Boolean(state.designType);
    }
    else if (step === 2) {
      return Boolean(state.tempalteId);
    }

    return false;
  }

  const onContinue = () => {
    if (step === 0) {
      setStep(1);
    }
    else if (step === 1 && state.designType === 'tempalte') {
      setStep(2);
    }
    else if (step === 1 && state.designType === 'blank') {
      dispatch(actions.createProject({
        name: state.projectName,
        onCreate: (id) => {
          onCreate(id as string);
        }
      }));
    }
    else if (step === 2 && state.tempalteId) {
      dispatch(actions.createProject({
        name: state.projectName,
        templateId: state.tempalteId,
        onCreate: (id) => {
          onCreate(id as string);
        }
      }));
    }

  }

  return (
    <Dialog
      open={open}
      cancelCallback={onCancel}
      okCallback={onContinue}
      title="Create Project"
      disabled={!canContinue()}
      inProgress={createProjectPending}
      okLabel={getLabel()}
    >
      <SwipeableViews index={step} animateHeight>
        <StepProjectName onNameChange={onNameChange} value={state.projectName} />
        <StepDesignType onChange={onDesignType} value={state.designType} />
        <StepTemplate onSelect={onSelectTemplate} selectedTemplate={state.tempalteId} />
      </SwipeableViews>
    </Dialog>
  );
};

export default CreateProjectDialog;