import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Typography, Paper, Box, CircularProgress, Fab } from "@material-ui/core";

import useResizeAware from 'react-resize-aware';

import {
  CanvasProvider,
  Canvas,
  Canvases,
  CanvasObjects,
  ObjectProperties,
  CanvasActions,
  AddCanvasButton,
  KeyboardControls,
  CanvasItem,
  ImportFrom,
  SizeFilter,
} from '../../components/Canvas';

import FilterToggles from "../../components/Canvas/FilterToggles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { actions, loadCanvases } from '../../services/projects';
import { actions as fontActions } from '../../services/fonts';
import orderBy from "lodash/orderBy";
import { FontDataLight, CanvasThumb } from "repix-common";
import { useStaticQuery, graphql } from "gatsby"

import DesignLayout from './DesignLayout';
import { useStyles } from './Design.styles';
import AddObjectButton from "../../components/Canvas/CanvasObjects/AddObjectButton";


const fontsQuery = graphql`
  query {
    systemFonts: allFontData {
      nodes {
        css
        default
        fontFamily
        id
        thumb
        type
      }
    }
    defaultFont: allFontData(filter: {default: {eq: true}}) {
      nodes {
        css
        default
        fontFamily
        id
        thumb
        type
      }
    }
  }
`

interface FontsData {
  systemFonts: {
    nodes: FontDataLight[]
  },
  defaultFont: {
    nodes: FontDataLight[]
  }
}

interface Props extends RouteComponentProps {
  projectId?: string
}

const CanvasPage: React.FC<Props> = ({ projectId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ resizeListener, canvasListSize ] = useResizeAware();


  const user = useSelector((state: RootState) => state.user.user);

  const state = useSelector((state: RootState) => state.projects);

  const fonts = useSelector((state: RootState) => state.fonts)

  const data = useStaticQuery<FontsData>(fontsQuery);

  const [ canvasesLoading, setCanvasesLoading ] = useState(true);
  const [ fontsLoading, setFontsLoading ] = useState(false);
  const [ canvases, setCanvases ] = useState<CanvasItem[]>([]);
  const [ availableFonts, setAvailableFonts ] = useState<FontDataLight[]>([]);

  const project = projectId ? state.projects[ projectId ] : undefined;

  useEffect(() => {
    dispatch(actions.loadProjectsLive());

    if (projectId) {
      dispatch(actions.updateLastOpenTime(projectId));
      dispatch(actions.setActiveProject(projectId));

      return () => {
        dispatch(actions.setActiveProject(null));
      }
    }
  }, []);

  const loadData = async () => {
    if (!projectId || !user) {
      return;
    }

    setCanvasesLoading(true);
    const result = await loadCanvases(projectId, user.id);
    setCanvases(result.canvases);
    if (result.fonts.length > 0) {
      setFontsLoading(true);
    }
    setCanvasesLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [ projectId ]);


  useEffect(() => {
    dispatch(fontActions.loadListLive());
    const userFonts: FontDataLight[] = fonts.userFonts.filter(item => item.status === 'active').map(item => ({
      id: item.id,
      fontFamily: item.fontFamily,
      css: item.css,
      thumb: item.thumb.path,
      type: 'user'
    }));

    const fontList = orderBy([ ...data.systemFonts.nodes, ...userFonts ], [ 'type', 'fontFamily' ], [ 'desc', 'asc' ]);
    setAvailableFonts(fontList);

  }, [ fonts.userFonts ]);

  if (!projectId) {
    return null;
  }

  const onCanvasAdd = (args: CanvasItem, thumb: CanvasThumb) => {
    if (!projectId) {
      return;
    }
    dispatch(actions.addCanvas(projectId, {
      id: args.id,
      width: args.width,
      height: args.height,
      data: args.data,
      thumb: thumb,
      template: args.template,
      design: args.design,
    }));
  }

  const onCanvasUpdate = (args: CanvasItem, thumb: CanvasThumb) => {
    if (!projectId) {
      return;
    }
    dispatch(actions.updateCanvas(projectId, {
      id: args.id,
      width: args.width,
      height: args.height,
      data: args.data as string,
      thumb: thumb,
    }));
  }

  const onCanvasDelete = (id: string) => {
    if (!projectId) {
      return;
    }
    dispatch(actions.deleteCanvas(projectId, id));
  }

  const onFontsLoaded = () => {
    // console.log('fonts done loading');
    setFontsLoading(false);
  }

  const onSizeFilterChange = (size: SizeFilter) => {
    if (!project) {
      return;
    }
    // console.log('update size filter', size);
    dispatch(actions.updateSizeFilter(project.id, size));
  }

  const loading = state.listLoading || canvasesLoading;

  if (loading || !project) {
    return (
      <DesignLayout loading={loading} projectId={projectId}></DesignLayout>
    )
  }

  // console.log('canvasListSize', canvasListSize);
  const availableHeight = canvasListSize.height ? canvasListSize.height - 120 : Math.max(Math.round(window.innerHeight - 350), 430);

  return (
    <CanvasProvider
      canvases={canvases}
      onFontsLoaded={onFontsLoaded}
      onCanvasAdd={onCanvasAdd}
      onCanvasUpdate={onCanvasUpdate}
      onCanvasDelete={onCanvasDelete}
      fonts={availableFonts}
      defaultFont={data.defaultFont.nodes[ 0 ]}
      sizeFilter={project.sizeFilter}
      onSizeFilterChange={onSizeFilterChange}
      availableHeight={availableHeight}
    >
      <DesignLayout loading={loading} projectId={projectId}>
        {
          loading || !project ? null : (
            <React.Fragment>
              <Paper className={classes.leftPanel}>
                <CanvasObjects />
              </Paper>
              <div className={classes.content}>
                <ObjectProperties />
                <div className={classes.exportAndFilter}>
                  <FilterToggles />
                  <ImportFrom />
                </div>

                <KeyboardControls />
                {/* <CopyFromType /> */}
                <div className={classes.canvasList}>
                  {resizeListener}
                  {
                    fontsLoading ? (
                      <Box>
                        <Typography paragraph>loading fonts...</Typography>
                        <CircularProgress />
                      </Box>
                    ) : (
                        <React.Fragment>
                          <Canvases onRender={({ id, index, isActive, visible }) => {
                            return (
                              <div className={classes.paperWrapper} key={id} style={{ display: visible ? 'inline-flex' : 'none' }}>
                                {
                                  visible ? <CanvasActions hidden={!isActive} /> : null
                                }
                                <Paper className={classes.paper} elevation={isActive ? 12 : 1}>
                                  <Canvas id={id} index={index} disabled={!isActive} visible={visible} />
                                </Paper>
                              </div>
                            )
                          }} />
                          <div className={classes.addButtonContainer}>
                            <AddCanvasButton className={classes.addButton} />
                          </div>
                        </React.Fragment>
                      )
                  }
                </div>
                <div className={classes.mobileBottomActions}>
                  <AddObjectButton className={classes.fabBtn} />
                  <AddCanvasButton className={classes.fabBtn} extended />
                </div>
              </div>
            </React.Fragment>
          )
        }
      </DesignLayout>
    </CanvasProvider>
  );
};

export default CanvasPage;